.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  
}

.Tringle-Img {
  clip-path: polygon(48.7% 0%, 100% 44%, 75.8% 100%, 24.6% 100%, 0% 44%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.text-Style{
 
  


  color: rgba(255, 255, 255, 1);
  text-shadow: 2px 2px 0px rgb(66, 126, 200), 
               2px -2px 0px rgb(94, 143, 248), 
			   -2px 2px 0px rgba(64, 116, 181, 1), 
			   -2px -2px 0px rgba(64, 116, 181, 1), 
			   2px 0px 0px rgb(143, 252, 249), 
			   0px 2px 0px rgba(64, 116, 181, 1), 
			   -2px 0px 0px rgba(64, 116, 181, 1), 
			   0px -2px 0px rgba(64, 116, 181, 1);
}


.company-logo {
max-height: 100px;
max-width: 200px;

}

.rounded-image {
  max-width: 100%;
  height: auto; 
  border-radius: 50%; 
  display: block; 
  margin: 0 auto; 
}

 .footer {
    background: #103a6e;
    color: white;
  } 


 
  
  .footer .copyright p {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }


  